/* eslint-disable max-len */
/* eslint-disable complexity */
import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import {makeStyles,
  Button,
  Select,
  MenuItem,
  FormControl,
  Icon,
  Box,
  SnackbarContent,
  FormLabel} from '@material-ui/core';
import * as yup from 'yup';
import {Green} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints, EzFormControl, YesNoRadioGroup, Tooltip, CallMePopover} from 'wobi-web-common';
import carBrandData from 'wobi-web-common/dist/assets/js/carBrandsData';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import CarBrandSelect from '../../../components/CarBrandSelect';
import GetAutoDetailsFrom from '../../../components/GetAutoDetailsForm';
import RenewalsLanding from '../../../components/RenewalsLanding';
import {LICENSE_NUMBER, setOffersFields} from '../../../utils/persistOfferDetails';
import {getAutoModelsAndSubModels, getAutoBrandsAndYears, handleContactUsSubmit} from '../../../utils/apiHandlers';
import {fullcoverPopularBrands} from '../../../assets/js/popularCarBrands';
import AlwaysOnTooltip from '../../../components/AlwaysOnTooltip';
import {fireGtmEvent, fireGtmEventWithUserId, gtmEventWrapper} from '../../../utils/marketing.utils';
import {
  setAutoDesc, updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields
  , updateForceTriggeredByInSessionStorageOffersFields,
} from '../../../utils/common';
import {ProductType} from '../../../enums';
import {EMPTY_STRING_VALUE} from '../../../constants';
import {detailInitialState, MAX_YEAR_FOR_ESP_QUESTION} from '../constants';
import {TRIGGERED_BY, abandonmentStep} from '../../../constants/enums';
import {getImageByAgent} from '../../../config/agent';

const BulbOnce = getImageByAgent('bulb_once.gif');
const FoundCarOnce = getImageByAgent('we_found_car_once.gif');
const SHOW_ESP_QUESTION_YEAR = 2010;
const SHOW_FCW_LDW_YEAR = 2018;

const AutoInfoTab = ({
  history,
  className,
  intl,
  formikHandleChange,
  formikSetField,
  formikValues,
  formikValidateFrom,
  formikResetForm,
  formikSetTouched,
  formikErrors,
}) => {
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});

  // State
  const [showAutoInfo, setShowAutoInfo] = useState(Boolean(formikValues.autoInfo));
  const [showAutoInfoControlled, setShowAutoInfoControlled] = useState(false);
  const [showRenewalsLanding, setShowRenewalsLanding] = useState(Boolean(sessionStorage.getItem('isRenewal')));
  const [autoInfo, setAutoInfo] = useState(formikValues.autoInfo || null);
  const [brandsAndYears, setBrandsAndYears] = useState({});
  const [modelsAndSubModels, setModelsAndSubModels] = useState({});
  const [detectEsp, setDetectEsp] = useState(false);

  // const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [models, setModels] = useState([]);
  const [subModels, setSubModels] = useState([]);
  const [brandIcon, setBrandIcon] = useState(null);
  const [espQuestion, shouldAskEspQuestion] = useState(false);
  const [renewal, setRenewal] = useState(JSON.parse(sessionStorage.getItem('isRenewal') || 'null'));
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const [haveAutoAnswer, setHaveAutoAnswer] = useState(false);
  const [isValidAutoInfo, setIsValidAutoInfo] = useState(false);
  const [clickEditAutoInfo, setClickEditAutoInfo] = useState(false);
  const isMobile = useMediaQuery(`(max-width:${1125}px)`);
  const [showPopupForContactUsIfCareNotPrivate, setShowPopupForContactUsIfCareNotPrivate] = useState(false);
  const [callMeSubmitted, setCallMeSubmitted] = useState(false);
  const [callMeid, setCallMeid] = useState();
  const [buttonsTextState, setButtonsTextState] = useState({});
  const [showQuestions, setShowQuestions] = useState(false);
  useEffect(() => {
    setCallMeSubmitted(false);
    if (formikValues.privateUCarAndOnlyPrivateUse === 'no') {
      setShowPopupForContactUsIfCareNotPrivate(true);
    }
  }, [formikValues]);
  useEffect(() => {
    formikResetForm();
  }, [showAutoInfo]);

  useEffect(() => {
    getAutoBrandsAndYears()
      .then(res => {
        setBrandsAndYears(res || {});
      });
  }, []);

  useEffect(() => {
    if (window.location.hash === '#auto-info') {
      formikSetField('fcw', '');
      formikSetField('ldw', '');
      formikSetField('hasEspClientAnswer', '');
    }
  }, [formikValues.licenseNumber]);

  useEffect(() => {
    const year = formikValues.year || autoInfo?.car_year;
    let obj = {};
    if (year && formikValues.licenseNumber) {
      if (year >= SHOW_FCW_LDW_YEAR) {
        obj = {
          fcw: '_yes',
          ldw: '_yes',
        };
        formikSetField('fcw', formikValues.fcw || 'yes');
        formikSetField('ldw', formikValues.ldw || 'yes');
      } else {
        obj = {
          fcw: '_no',
          ldw: '_no',
        };
        setShowQuestions(true);
        formikSetField('fcw', formikValues.fcw || 'no');
        formikSetField('ldw', formikValues.ldw || 'no');
      }
      if (year <= SHOW_ESP_QUESTION_YEAR) {
        obj.esp = '_no';
        formikSetField('hasEspClientAnswer', formikValues.hasEspClientAnswer || 'no');
      } else {
        formikSetField('hasEspClientAnswer', formikValues.hasEspClientAnswer || 'yes');
      }

      setButtonsTextState(obj);
    } else {
      setButtonsTextState({});
    }
  }, [formikValues.year, autoInfo]);

  const handleCallMeSubmit = async (values) => {
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_1.key);
    updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_2CLAIMS_OR_MORE);
    fireGtmEventWithUserId(values.phone, 'Above2PropClaimsContactUs');
    setCallMeSubmitted(true);
    const result = await handleContactUsSubmit(
      values,
      {...formikValues, ...values, autoInfo},
      TRIGGERED_BY.WEBSITE_COMPANY_CAR,
    );
    if (result.success) {
      // setCallMeSubmitSuccess(true);
      setTimeout(() => setShowPopupForContactUsIfCareNotPrivate(false), 3000);
    }
  };
  useEffect(() => {
    if (showPopupForContactUsIfCareNotPrivate) {
      setCallMeid('call-me-popover1');
    } else {
      setCallMeid();
    }
  }, [showPopupForContactUsIfCareNotPrivate]);

  // separate method to clear formik form but save license number
  const resetFormWithoutLicenseNumber = useCallback(() => {
    const SHOW_INFO_MODAL = true;
    formikResetForm({
      values: {
        ...detailInitialState(),
      },
    });
    setShowAutoInfoControlled(SHOW_INFO_MODAL);
  }, [formikResetForm, setShowAutoInfoControlled]);

  const handleAutoInfoSelects = async (fieldName, value) => {
    if (fieldName !== 'brand') {
      formikSetField(fieldName, value.toString());
    }

    switch (fieldName) {
    case 'brand': {
      const brand = value?.value ? value.value.toString() : value;

      if (!brand) {
        break;
      }

      if (brandsAndYears[brand]) {
        const brandYears = brandsAndYears[brand]
          .years
          .map(year => ({text: year, value: year}));
        setYears(brandYears);
      }

      fireGtmEvent('AutoType', {value: safeParseInt(brand)});
      formikSetField('brand', value);

      setModels([]);
      setSubModels([]);
      formikSetField('year', '');
      formikSetField('model', '');
      formikSetField('subModel', '');
      break;
    }
    case 'year': {
      const data = {brand: formikValues.brand,
        year: value};
      shouldAskEspQuestion(value <= MAX_YEAR_FOR_ESP_QUESTION);
      formikSetField('hasEspClientAnswer', '');
      const resp = await getAutoModelsAndSubModels(data);
      setModelsAndSubModels(resp);
      setModels(generateModelsOptions(resp));

      setSubModels([]);
      formikSetField('model', '');
      formikSetField('subModel', '');
      break;
    }
    case 'model': {
      setSubModels(generateSubModelsOptions(modelsAndSubModels, value));
      if (formikSetField) {
        formikSetField('subModel', '');
      }
      break;
    }
    case 'subModel': {
      if (espQuestion &&
        modelsAndSubModels[formikValues.model].subModels[value].car_year <= MAX_YEAR_FOR_ESP_QUESTION) {
        formikSetField('hasEspClientAnswer', modelsAndSubModels[formikValues.model]
          .subModels[value].esp === 1 ? 'yes' : '');
        setDetectEsp(modelsAndSubModels[formikValues.model]
          .subModels[value].esp === 1);
      }
      setAutoInfo(modelsAndSubModels[formikValues.model].subModels[value]);
      break;
    }
    default:
      break;
    }
  };

  const handleEditClick = () => {
    setClickEditAutoInfo(true);
    if (formikValues.brand && years.length) {
      setAutoInfo(null);
    } else {
      setIsEditInProgress(true);
      handleAutoInfoSelects('brand', autoInfo.manufacturer_id);

      setOffersFields({
        [LICENSE_NUMBER]: EMPTY_STRING_VALUE,
      });

      formikSetField(LICENSE_NUMBER, EMPTY_STRING_VALUE);
    }
  };

  React.useEffect(() => {
    if (formikValues.brand && autoInfo && !formikValues.year) {
      handleAutoInfoSelects('year', autoInfo.car_year);
    }
  }, [formikValues]);

  React.useEffect(() => {
    if (Object.entries(modelsAndSubModels).length && autoInfo) {
      handleAutoInfoSelects('model', autoInfo.car_model);
      setIsEditInProgress(false);
      setAutoInfo(null);
    }
  }, [modelsAndSubModels]);

  React.useEffect(() => {
    if (autoInfo) {
      setBrandIcon(getIcon(autoInfo));
    }
  }, [autoInfo]);

  const handleNoLicenseNumberClick = () => {
    setClickEditAutoInfo(true);
    setShowAutoInfo(true);
    fireGtmEvent('DontHaveNumber');
    setOffersFields({
      [LICENSE_NUMBER]: EMPTY_STRING_VALUE,
    });
  };
  const safeParseInt = (value) => {
    if (Number.parseInt(value, 10)) {
      return Number.parseInt(value, 10);
    }
  };

  const handleSubmitStep = async () => {
    const errors = await formikValidateFrom();
    const shouldCheckEsp = espQuestion && errors.hasEspClientAnswer;
    const hasErrors = Boolean(errors.ldw || errors.fcw ||
      errors.privateUCarAndOnlyPrivateUse || shouldCheckEsp);

    if (!autoInfo || hasErrors) {
      Object.keys(autoInfoValidations).forEach(field => formikSetTouched(field, true));
      return;
    }

    let data = formikValues;

    if (!formikValues.brand) {
      data = Object.assign(data, {
        brand: autoInfo.manufacturer_id.toString(),
        model: autoInfo.car_model.toString(),
        subModel: `${autoInfo.car_model}${autoInfo.car_year}`,
        year: autoInfo.car_year.toString(),
      });
    }
    autoInfo.esp = !espQuestion ? autoInfo.esp :
      formikValues.hasEspClientAnswer === true ||
        formikValues.hasEspClientAnswer === 'yes' ? 1 : 0;

    formikSetField('autoInfo', autoInfo);
    setOffersFields({...data, autoInfo});

    fireGtmEvent('ConfirmAutoDetails', {
      AutoType: safeParseInt(formikValues.brand),
      Fcw: formikValues.fcw,
      Ldw: formikValues.ldw,
      PrivateOwnerOnly: formikValues.privateUCarAndOnlyPrivateUse,
    });

    history.push('#drivers');
  };

  const {policyType} = JSON.parse(sessionStorage.getItem('offers_fields'));

  const isNot3rdParty = policyType !== ProductType.ThirdParty;

  const tooltipIcon = () => (
    <div className='divTooltipIcon'>
      <AlwaysOnTooltip
        show={formikValues.ldw === 'yes' && formikValues.fcw === 'yes' && autoInfo?.eq_level >= 7}
        kind='Hat'
        showText
        className={`${classes.tooltip} fcwLdwYes`}
      >
        <span>
          <span className={classes.mainCarTooltipExcellentText}>
            {messages('auto_info.not_main_message.excellent.text')}
          </span>
          {messages('auto_info.tooltip_text_ldw_fcw_yes_all_1')}
          <br/>
          {messages('auto_info.tooltip_text_ldw_fcw_yes_all_2')}
        </span>
      </AlwaysOnTooltip>
    </div>
  );

  const submitButtonAriaLabel = () => {
    if (formikValues.privateUCarAndOnlyPrivateUse === 'no') {
      return `${messages('auto_info.continue_to_drivers')}` +
        `${messages('auto_info.private_car_tooltip_unable_to_insure')}`;
    } else if (formikErrors && Object.keys(formikErrors).length) {
      return `${messages('auto_info.continue_to_drivers')} ${messages('validation.needToFillAllFields')}`;
    } else {
      return null;
    }
  };

  const autoInfoDetails = (
    <div className={`${className} auto-info-details`} id='vertical-tabpanel-0' role='tabpanel'>
      <div className={`${classes.autoInfoDetails}`}>
        <div className='borderBottom'>
          <div className={`title-row ${classes.flex}`}>
            {
              autoInfo ?
                <FormattedMessage tagName='span' id='auto_info.located_auto_info'/> :
                !isMobile && !clickEditAutoInfo ? (<div className={classes.autoInfoDetectedTextContainer}>
                  <img alt='bulb' className={classes.noAutoInfoDetectedImage} src={BulbOnce}/>
                  <span className={classes.autoInfoDetectedText}>
                    { messages('auto_info.please_fill_manual_auto_details')}
                  </span>
                </div>) : <FormattedMessage tagName='span' id='auto_info.fill_auto_info'/>
            }
            <Button disabled={isEditInProgress} onClick={handleEditClick}
              className={`${classes.noFill} ${autoInfo ? '' : classes.hide}`}>
              {intl.formatMessage({id: 'auto_info.edit_details'})}
            </Button>
          </div>
          {
            autoInfo ?
              <div className={classes.autoInfoFoundDetails}>
                <div className='details'>
                  {formikValues.licenseNumber ? <div>
                    <FormattedMessage tagName='span'
                      id='fields.licenseNumber'/>
                    {formikValues.licenseNumber}
                  </div> : false}
                  <div>
                    <FormattedMessage tagName='span' id='fields.brand'/>
                    {brandIcon}
                    {autoInfo.manufacturer}
                  </div>
                  <div>
                    <FormattedMessage tagName='span' id='fields.year'/>
                    {autoInfo.car_year}
                  </div>
                  <div>
                    <FormattedMessage tagName='span' id='fields.model'/>
                    {autoInfo.main_car_desc}
                  </div>
                  <div>
                    <FormattedMessage tagName='span' id='fields.subModel'/>
                    {setAutoDesc(autoInfo)}
                  </div>
                  {formikValues.licenseNumber ?
                    <>
                      <div className={classes.textContainerEspFcwLdw}>
                        <FormattedMessage tagName='p' id='auto_info.ldw_yes' />
                        {formikValues.ldw === 'no' || showQuestions ? (
                          <YesNoRadioGroup value={formikValues.ldw}
                            onChange={event => {
                              formikSetField('ldw', event.target.value);
                              gtmEventWrapper(formikHandleChange, 'Ldw').invoke(event);
                            }} />
                        ) : null}
                        <Tooltip
                          name='ldw'
                          className={classes.tooltip}
                          placement='left'
                          title={<span dangerouslySetInnerHTML={{__html: messages('auto_info.tooltip_text_ldw')}} />}
                        >
                          <span><FormattedMessage id='text.not_sure' /></span>
                        </Tooltip>
                      </div>
                      <div className={classes.textContainerEspFcwLdw}>
                        <FormattedMessage tagName='p' id='auto_info.fcw_yes' />
                        {formikValues.fcw === 'no' || showQuestions ? (
                          <YesNoRadioGroup value={formikValues.fcw}
                            onChange={event => {
                              formikSetField('fcw', event.target.value);
                              gtmEventWrapper(formikHandleChange, 'Fcw').invoke(event);
                            }} />
                        ) : null}
                        <Tooltip
                          name='fcw'
                          className={classes.tooltip}
                          placement='left'
                          title={<span dangerouslySetInnerHTML={{__html: messages('auto_info.tooltip_text_fcw')}} />}
                        >
                          <span><FormattedMessage id='text.not_sure' /></span>
                        </Tooltip>
                      </div>
                      {(formikValues.year || autoInfo?.car_year) <= SHOW_ESP_QUESTION_YEAR ? (
                        <div className={classes.textContainerEspFcwLdw}>
                          <FormattedMessage tagName='p' id='auto_info.hasEspClientAnswer_yes' />
                          <YesNoRadioGroup value={formikValues.hasEspClientAnswer}
                            onChange={event => {
                              formikSetField('hasEspClientAnswer', event.target.value);
                              gtmEventWrapper(formikHandleChange, 'HasEspClientAnswer').invoke(event);
                            }} />
                          <Tooltip
                            className={classes.tooltip}
                            name='hasEspClientAnswer'
                            placement='left'
                            title={messages('auto_info.espMoreInfo')}>
                            <span><FormattedMessage id='text.not_sure' /></span>
                          </Tooltip>
                        </div>

                      ) : null

                      }
                      <AlwaysOnTooltip show
                        className={classes.privateCarTooltip}
                        kind='Alert' showText>
                        {messages('auto_info.editDone')}
                      </AlwaysOnTooltip>
                    </> :
                    null }
                </div>
                {!isMobile && !clickEditAutoInfo && (<div className={classes.autoInfoDetectedImageContainer}>
                  <img alt='confetti' className={classes.autoInfoDetectedImage} src={FoundCarOnce}/>
                </div>)}
              </div> :
              false
          }
          {
            autoInfo ?
              false : (
                <div className={`details-form ${autoInfo ? classes.hide : ''}`}>
                  <FormControl className={`${classes.fullWidth} auto-select`} variant='outlined'>
                    <EzFormControl name='brand'>
                      <CarBrandSelect value={formikValues.brand}
                        onSelect={(value) => {
                          handleAutoInfoSelects('brand', value);
                        }}
                        defaultChipsItems={formikValues.policyType === 'FULLCOVER' ?
                          fullcoverPopularBrands : false}
                        items={carBrandData}/>
                    </EzFormControl>
                  </FormControl>

                  <div className='wrapper'>
                    <EzFormControl
                      className='auto-select'
                      name='year'
                      disabled={!years.length}
                      label={messages('fields.year')}
                      labelFor='auto-select-year'
                      isInputLabel
                    >
                      <Select
                        id='auto-select-year'
                        className={`${classes.fullWidth}`}
                        labelId='year-label'
                        disabled={!years.length}
                        value={formikValues.year}
                        onChange={({target: {value}}) => handleAutoInfoSelects('year', value, formikValues)}
                      >
                        {years.map(year => (
                          <MenuItem key={`year-${year.value}`} value={year.value}>
                            {year.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </EzFormControl>
                    <EzFormControl
                      className='auto-select'
                      name='model'
                      disabled={!models.length}
                      label={messages('fields.model')}
                      labelFor='auto-select-model'
                      isInputLabel
                    >
                      <Select
                        id='auto-select-model'
                        labelId='model-label'
                        className={`${classes.fullWidth}`}
                        disabled={!models.length}
                        value={formikValues.model}
                        onChange={({target: {value}}) => handleAutoInfoSelects('model', value)}
                      >
                        {models.map(model => (
                          <MenuItem key={`model-${model.value}`} value={model.value}>
                            {model.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </EzFormControl>
                    <EzFormControl
                      className='auto-select'
                      name='subModel'
                      disabled={!subModels.length}
                      label={messages('fields.subModel')}
                      labelFor='auto-select-submodel'
                      isInputLabel
                    >
                      <Select
                        id='auto-select-submodel'
                        labelId='subModel-label'
                        className={`${classes.fullWidth}`}
                        disabled={!subModels.length}
                        value={formikValues.subModel}
                        onChange={({target: {value}}) => handleAutoInfoSelects('subModel', value, formikValues)}
                      >
                        {subModels.map(subModel => (
                          <MenuItem key={`subModel-${subModel.value}`} value={subModel.value}>
                            {subModel.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </EzFormControl>
                  </div>
                </div>
              )}

        </div>
        <div className='yes-no'>
          <div id='popoverAnchor1' >
            <EzFormControl name='privateUCarAndOnlyPrivateUse'
              label={messages('auto_info.privateUCarAndOnlyPrivateUse')} isLabelInChild>
              <YesNoRadioGroup value={formikValues.privateUCarAndOnlyPrivateUse}
                onChange={gtmEventWrapper(formikHandleChange, 'PrivateOwnerOnly').invoke}/>
              <Tooltip
                className={classes.tooltip}
                name='privateUCarAndOnlyPrivateUse'
                placement='left'
                title={messages('auto_info.private_car_tooltip')}>
                <span><FormattedMessage id='text.not_sure'/></span>
              </Tooltip>
            </EzFormControl>
            <CallMePopover
              id={callMeid} anchorEl={document.querySelector('#popoverAnchor1')}
              open={showPopupForContactUsIfCareNotPrivate}
              onClose={ () => setShowPopupForContactUsIfCareNotPrivate(false)}
              onSubmit={handleCallMeSubmit}
              message={callMeSubmitted ? <FormattedMessage id='auto_info.call_me_message_success'/> :
                <FormattedMessage id='auto-info.call_me_message'/>}
              secondaryMessage = {<FormattedMessage id='auto_info.call_me_message_representative'/>}
              callMeSubmitted={callMeSubmitted}
            />
          </div>
          { autoInfo && formikValues.licenseNumber ? null :
            <div style={{alignItems: 'center', flexFlow: 'row wrap'}}>
              <div>
                <div>
                  <EzFormControl name='ldw' label={messages(`auto_info.ldw${buttonsTextState.ldw || ''}`)} isLabelInChild>
                    <YesNoRadioGroup value={formikValues.ldw}
                      onChange={gtmEventWrapper(formikHandleChange, 'Ldw').invoke} />
                    <Tooltip
                      name='ldw'
                      className={classes.tooltip}
                      placement='left'
                      title={<span dangerouslySetInnerHTML={{__html: messages('auto_info.tooltip_text_ldw')}} />}
                    >
                      <span><FormattedMessage id='text.not_sure' /></span>
                    </Tooltip>
                  </EzFormControl>
                </div>
                <div style={{padding: '25px 0 0'}}>
                  <EzFormControl name='fcw' label={messages(`auto_info.fcw${buttonsTextState.fcw || ''}`)} isLabelInChild>
                    <YesNoRadioGroup value={formikValues.fcw}
                      onChange={gtmEventWrapper(formikHandleChange, 'Fcw').invoke} />
                    <Tooltip
                      name='fcw'
                      className={classes.tooltip}
                      placement='left'
                      title={<span dangerouslySetInnerHTML={{__html: messages('auto_info.tooltip_text_fcw')}} />}
                    >
                      <span><FormattedMessage id='text.not_sure' /></span>
                    </Tooltip>
                  </EzFormControl>
                </div>
              </div>
              {tooltipIcon()}
            </div> }

          {espQuestion && !formikValues.licenseNumber && (<div>
            <EzFormControl name='hasEspClientAnswer'
              label={<FormLabel>{messages(`auto_info.hasEspClientAnswer${buttonsTextState.esp || ''}`)}</FormLabel>} isLabelInChild isCustomLabel>
              <YesNoRadioGroup value={formikValues.hasEspClientAnswer}
                onChange={gtmEventWrapper(formikHandleChange, 'hasEspClientAnswer').invoke}/>
              <Tooltip
                className={classes.tooltip}
                name='hasEspClientAnswer'
                placement='left'
                title={messages('auto_info.espMoreInfo')}>
                <span><FormattedMessage id='text.not_sure'/></span>
              </Tooltip>
            </EzFormControl>
            <AlwaysOnTooltip show={detectEsp &&
            formikValues.hasEspClientAnswer === 'yes'}
            className={classes.privateCarTooltip}
            kind='Alert' showText>
              {messages('auto_info.espNote')}
            </AlwaysOnTooltip>
          </div>)}

          {isNot3rdParty && <div>
            <div>
              <EzFormControl name='haveAnotherCar' label={messages('auto_info.second_car')}
                isLabelInChild>
                <YesNoRadioGroup value={formikValues.haveAnotherCar}
                  onChange={gtmEventWrapper(formikHandleChange, 'HaveAnotherAuto').invoke}>
                  <AlwaysOnTooltip
                    show={formikValues.haveAnotherCar === 'yes'}
                    className='driversCountTooltip'
                    bubbleClassName={classes.mainCarTooltipBubble}
                    kind='Hat' showText
                  >
                    <FormattedMessage
                      id='drivers.good_choice'
                      values={{
                        value: <span>
                          <span style={{color: agentDeveloperUtils.getConvertedColor(Green[2])}}>
                            <FormattedMessage
                              id='insurance_history.successful_tootip_excelent'/>
                          </span>
                          <span style={{color: agentDeveloperUtils.getConvertedColor(Green[2])}}>!</span>
                        </span>,
                      }}
                    />
                  </AlwaysOnTooltip>
                </YesNoRadioGroup>
              </EzFormControl>
            </div>
          </div>}
        </div>
        {
          formikValues.ldw === 'yes' || formikValues.fcw === 'yes' ?
            (
              <Box mb={2}>
                <SnackbarContent message={<FormattedMessage id='auto_info.tooltip_text_ldw_fcw_yes'/>}/>
              </Box>
            ) : false
        }
        <Button
          className='submit'
          disabled={formikValues.privateUCarAndOnlyPrivateUse === 'no'}
          onClick={handleSubmitStep}
          aria-label={submitButtonAriaLabel()}
        >
          {messages('auto_info.continue_to_drivers')}
          <Icon>arrow_back</Icon>
        </Button>
      </div>
    </div>
  );

  return showRenewalsLanding ?
    <RenewalsLanding
      intl={intl}
      className={className}
      renewal={renewal}
      handleChoseCarClick={handleNoLicenseNumberClick}
      formikSetField={formikSetField} setAutoInfo={setAutoInfo}
      setShowAutoInfo={setShowAutoInfo} setShowRenewalsLanding={setShowRenewalsLanding}
    /> : showAutoInfo || showAutoInfoControlled ?
      autoInfoDetails :
      <GetAutoDetailsFrom
        classes={classes}
        intl={intl}
        className={className}
        handleNoLicenseNumberClick={handleNoLicenseNumberClick}
        formikSetField={formikSetField} setAutoInfo={setAutoInfo} setRenewal={setRenewal}
        setShowAutoInfo={setShowAutoInfo} setShowRenewalsLanding={setShowRenewalsLanding}
        haveAutoAnswer={haveAutoAnswer} setHaveAutoAnswer={setHaveAutoAnswer}
        isValidAutoInfo={isValidAutoInfo} setIsValidAutoInfo={setIsValidAutoInfo}
        isMobile={isMobile}
        resetFormWithoutLicence={resetFormWithoutLicenseNumber}
        shouldAskEspQuestion={shouldAskEspQuestion}
        setDetectEsp={setDetectEsp}
      />;
};

const generateModelsOptions = (modelsAndSubmodels) => Object.values(modelsAndSubmodels)
  .map(model => ({text: model.main_car_desc,
    value: model.car_model}))
  .sort((a, b) => a.text.localeCompare(b.text));

const generateSubModelsOptions = (modelsAndSubModels, model) => {
  const modelObject = modelsAndSubModels[model];
  if (!modelObject) {
    return [];
  }
  return Object.values(modelObject.subModels)
    .map(subModel => ({
      text:
            setAutoDesc(subModel),
      value: subModel.id,
    }));
};

const getIcon = (autoInfo) => {
  const item = carBrandData.find(carBrand => autoInfo.manufacturer_id === carBrand.value);
  return item.imgSrc ? <img src={item.imgSrc} className='brand-logo' alt='brand-logo'/> : '';
};

const autoInfoTab = {
  '& .loader-container + .text': {
    fontSize: 14,
  },
  maxWidth: 430,
  minWidth: 220,
  width: '100%',
};
autoInfoTab[`@media screen and (max-width: ${breakpoints.mobile}px)`] = {
  '& .license-number-wrapper': {
    maxWidth: 'unset',
  },
  maxWidth: 'unset',

  textAlign: 'center',
};
const buttons = {
  '& > *': {
    fontSize: 18,
    height: 52,
    lineHeight: 1,
    width: '50%',
  },
  display: 'flex',
  marginBottom: 50,
  marginTop: 15,
};
buttons['@media screen and (max-width: 960px)'] = {
  '& > *': {
    fontSize: 16,
  },
};
buttons[`@media screen and (max-width: ${breakpoints.mobile}px)`] = {
  '& > *': {
    width: '100%',
  },
  flexDirection: 'column',
};
const yesNoDiv = {
  alignItems: 'flex-start',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'flex-start',
  padding: '25px 0 0',
};
yesNoDiv[`@media screen and (max-width: ${breakpoints.mobile}px)`] = {
  '& > span': {
    display: 'none',
  },
  '& fieldset > div': {
    '& legend': {
      marginBottom: 10,
    },
    alignItems: 'unset',
    flexDirection: 'column',
  },
  padding: '10px 0 0',
};
const detailsFormStyle = {
  '& .auto-select': {
    margin: '15px 0 0',
    minWidth: 268,
  },
  '& > .wrapper': {
    maxWidth: 371,
    width: '100%',
  },
  paddingBottom: 25,
};
detailsFormStyle[`@media screen and (max-width: ${breakpoints.mobile}px)`] = {
  '& > .wrapper': {
    maxWidth: 'unset',
  },
};

const divTooltipIconStyle = {};
divTooltipIconStyle['@media screen and (min-width: 1567px)'] = {
  position: 'relative',
  top: '-7px',
};

const useStyles = makeStyles(theme => ({
  autoInfoDetails: {
    '& .borderBottom': {
      '&:first-child': {
        paddingTop: 0,
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      borderBottom: `2px dotted ${agentDeveloperUtils.getSecondaryColor(100)}`,
    },
    '& .details': {
      '& .brand-logo': {
        borderRight: '1px solid #ECF5F8',
        marginRight: 10,
        paddingRight: 10,
        width: 50,
      },
      '& > div': {
        '& > span': {
          fontWeight: 'normal',
          width: 90,
        },
        alignItems: 'center',
        color: theme.palette.secondary.main,
        display: 'flex',
        fontSize: theme.typography.fontSize,
        fontWeight: 600,

        minHeight: 60,
      },
      marginBottom: 30,
    },

    '& .details-form': detailsFormStyle,

    '& .title-row': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(18.37),
      fontWeight: 500,
    },
    '& .yes-no': {
      '& .divTooltipIcon': divTooltipIconStyle,

      '& .Mui-error': {
        display: 'block',
      },

      '& .MuiFormLabel-root': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: 500,
        maxWidth: 300,
        padding: '5px 0',
      },

      '& > div': yesNoDiv,
      '& > div.withTooltip': {
        '& .MuiFormControl-fullWidth': {width: 'auto'},
        [theme.breakpoints.down(1440)]: {maxWidth: 438},
        flexFlow: 'row wrap',
      },
      '& > div.withTwoTooltips': {
        '& .MuiFormControl-fullWidth': {width: 'auto'},
        [theme.breakpoints.down(1480)]: {maxWidth: 531},
        flexFlow: 'row wrap',
      },
      '& button': {
        '& .MuiIcon-root': {
          marginLeft: 10,
        },
        marginTop: '20px',
        maxWidth: 268,
        width: '100%',
      },

      padding: '20px 0',
    },
  },
  autoInfoDetectedImage: {
    width: 290,
  },
  autoInfoDetectedImageContainer: {
    left: 270,
    pointerEvents: 'none',
    position: 'absolute',
    top: -40,
    [theme.breakpoints.down('md')]: {
      left: 190,
    },
  },
  autoInfoDetectedText: {
    color: theme.palette.secondary.main,
    fontSize: 15,
    textAlign: 'center',
  },
  autoInfoDetectedTextContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    left: -22,
    position: 'relative',
  },
  autoInfoFoundDetails: {
    alignItems: 'flex-start',
    display: 'flex',
    position: 'relative',
  },
  autoInfoTab,
  buttons,
  customLabelWidth: {
    '& legend': {
      width: 300,
    },
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  inline: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  licenseNumberWrapper: {
    maxWidth: 336,
  },
  loader: {
    zIndex: 9,
  },
  loaderInfoImageContainer: {
    right: 0,
  },
  loaderNoInfoImage: {
    width: theme.typography.pxToRem(150),
  },
  loaderNoInfoText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24.2),
    textAlign: 'center',
  },
  loaderPaper: {
    maxWidth: '50%',
    padding: 30,
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%',
    },
  },
  mainCarTooltip: {
    [theme.breakpoints.up(1550)]: {marginLeft: 20},
    '& .icon': {height: 42},
  },
  mainCarTooltipBubble: {
    maxWidth: 220,
  },
  mainCarTooltipExcellentText: {
    color: agentDeveloperUtils.getConvertedColor(Green[2]),
  },
  marginRight: {
    marginLeft: 30,
  },
  noAutoInfoDetectedImage: {
    marginTop: -15,
    width: 100,
  },
  noFill: {
    '&.MuiButton-root.MuiButton-containedPrimary': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: 'unset',
      boxShadow: 'unset',
      opacity: 0.8,
    },
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    lineHeight: 1,
    padding: '8px 12px',
  },
  privateCarTooltip: {
    [theme.breakpoints.up(1480)]: {
      '&>.bubble': {maxWidth: 144},
      '&>.icon': {height: 42},
      marginLeft: 20,
    },
  },
  show: {
    opacity: '1 !important',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12.25),
    marginBottom: 20,
  },
  textContainerEspFcwLdw: {
    '& p': {
      flex: 0.8,
    },
    display: 'flex',
  },
  textFieldWrapper: {
    '& > img': {
      opacity: 0,
      position: 'absolute',
      right: 25,
      top: 'calc(50% - 5px)',
      transform: 'translateY(-50%)',
      transition: 'all 0.3s',
    },
    position: 'relative',
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(18.4),
    fontWeight: 600,
    margin: 0,
    marginBottom: 10,
  },
  tooltip: {
    '&.fcwLdwYes': {
      marginLeft: 0,
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
    borderBottom: '2px solid transparent',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize,
    marginLeft: 30,
    padding: '5px 0',
    transition: '0.3s all',
  },
  wrapperDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

AutoInfoTab.propTypes = {
  className: PropTypes.string,
  formikErrors: PropTypes.object,
  formikHandleChange: PropTypes.func,
  formikResetForm: PropTypes.func,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
};

export {
  useStyles,
};

export const autoInfoValidations = {
  brand: yup.string().required('שדה חובה'),
  fcw: yup.string().required('שדה חובה'),
  hasEspClientAnswer: yup.string().required('שדה חובה'),
  haveAnotherCar: yup.string().required('שדה חובה'),
  ldw: yup.string().required('שדה חובה'),
  model: yup.string().required('שדה חובה'),
  privateUCarAndOnlyPrivateUse: yup.string().required('שדה חובה'),
  subModel: yup.string().required('שדה חובה'),
  year: yup.string().required('שדה חובה'),
};

export default injectIntl(withRouter(AutoInfoTab));
